/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export function onClientEntry() {
  const CLEANUP_INTERVAL = 60000; // 60 seconds
  let lastActivityTime = Date.now();

  async function cleanupStorage() {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach(function(c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(cache => caches.delete(cache)));
    }
    console.log('Browser storage cleaned');
  }

  async function checkAndCleanup() {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { usage, quota } = await navigator.storage.estimate();
      const usagePercentage = (usage / quota) * 100;
      if (usagePercentage > 0) {
        cleanupStorage();
      }
    } else {
      console.warn('The storage API is not available in this browser.');
    }
  }

  function updateLastActivityTime() {
    lastActivityTime = Date.now();
  }

  // Update the last activity time on every interaction
  ['click', 'keypress', 'scroll', 'mousemove'].forEach(function(event) {
    window.addEventListener(event, updateLastActivityTime);
  });

  // Check and cleanup periodically
  setInterval(checkAndCleanup, CLEANUP_INTERVAL);

  // Try to cleanup on page close (as backup)
  window.addEventListener('beforeunload', cleanupStorage);
}
